// Our main CSS
import '../css/app.css'

if (document.getElementsByClassName('news-marquee')) {
  import('./marquee').then((module) => {
    module.default()
  })
}

if (document.getElementById('news')) {
  import('./news').then((module) => {
    module.default()
  })
}

if (document.getElementById('coworkers')) {
  import('./coworkers').then((module) => {
    module.default()
  })
}

if (document.getElementById('properties')) {
  import('./properties').then((module) => {
    module.default()
  })
}

if (document.getElementById('accordions')) {
  import('./accordion').then((module) => {
    module.default()
  })
}

if (document.querySelector('.glightbox')) {
  import('./glightbox').then((module) => {
    module.default()
  })
}

if (document.querySelector('.mobile-nav')) {
  import('./mobileMenu').then((module) => {
    module.default()
  })
}
